<template>
    <div class="container">
        <el-dialog title="邀请逻辑" width='650px' v-model="dialogVisible">

            <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="120px" class="ruleForm">
                <el-form-item label="请选择：" prop="Status">
                    <div class="radio">
                        <el-radio-group v-model="ruleForm.Status">
                            <div class="cell" style="height:30px" >
                                <el-radio :label="1">仅邀请从未进过群的用户:</el-radio>
                            </div>
                            <div class="cell" style="margin-top:5px;">
                                <el-radio :label="0">添加时都会发送邀请</el-radio>
                            </div>
                        </el-radio-group>
                    </div>
                </el-form-item>
            </el-form>
            <p class="tips">补邀请逻辑：当群满无法拉人时，新建群后，系统将自动补3天内的邀请任务</p>
            <template #footer>
                <div class="dialog-footer">
                    <div class="footer">
                        <p>注意：切换时将会有5分钟左右延迟</p>
                        <div>
                            <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
                            <el-button @click="dialogVisible = false">取 消</el-button>
                        </div>
                       
                    </div>
                    
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { onMounted, reactive, ref, unref,nextTick } from "vue";
import {ConfigGetJoinGroupStatus,ConfigSetJoinGroupStatus} from "@/helper/group";
import { ElMessage, ElMessageBox } from "element-plus";
import _ from "lodash";
export default {
  components: {},
  setup(props, context) {
    const dialogVisible = ref(false);
    const ruleFormRef = ref(null);
    const ruleForm = reactive({
      Status: 1
    });
    const rules = reactive({
      Status: [
        { required: true, message: "请选择", trigger: "change" },
      ],
    });

    onMounted(() => {
    });

    function initCop(){
        dialogVisible.value = true;
        ConfigGetJoinGroupStatus().then(res=>{
            ruleForm.Status=res.Status
        })            
        // resetData()
    }

    function resetData(){
        ruleForm.Status=1
    }

    /*************提交*****************/
    //确定表单
    const submitForm = _.debounce(
      async () => {
        const form = unref(ruleFormRef);
        if (!form) return;
        try {
          await form.validate();
            ElMessageBox.confirm("您确定要切换吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                ConfigSetJoinGroupStatus({Status:ruleForm.Status}).then(res=>{
                    dialogVisible.value = false;
                    context.emit("onConfirm");
                })
            })
            .catch(() => {
                ElMessage({
                    type: "info",
                    message: "已取消",
                });
            });

        } catch (error) {
          console.log(error);
        }
      },
      1000,
      {
        leading: true, //在延迟开始前立即调用事件
        trailing: false, //在延时结束后不调用,保证事件只被触发一次
      }
    );


    return{
        dialogVisible,
        initCop,
        ruleFormRef,
        ruleForm,
        rules,
        submitForm,
        resetData
    }
  },
};
</script>

<style lang="scss" scoped>
.ruleForm {
  // width: 400px;
  margin: 20px 20px 0;
}
.cell{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  
}
.footer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    p{
        color: red;
        font-size: 12px;
        margin-right: 20px;
    }
}
.tips{
    color: red;
    font-size: 12px;
    margin: 20px 60px 0;

}
</style>