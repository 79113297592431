<template>
  <div>
    <div class="table-head">
      <div class="table-search">
        <el-space>
          <div>
            <span>助手名称或ID:</span>
            <el-input
              v-model="searchOption.key"
              placeholder="请输入助手名称或ID"
            ></el-input>
          </div>
          <div>
            <span>助手状态:</span>
            <el-select
              v-model="searchOption.status"
              value-key="RobotID"
              placeholder="全部"
            >
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </el-space>
        <div>
          <el-button type="primary" @click="searchAction">搜索</el-button>
          <el-button @click="resetAction">重置</el-button>
        </div>
      </div>
    </div>
    <div class="line-height"></div>
    <div class="table-box">
      <div class="list-title">
        <div>
          共<span>{{ pageObj.TotalRow || 0 }}</span>个助手(开启入群状态下的助手，被添加后会自动发送入群链接)
        </div>
        <div>
          <el-button type="primary" @click="BatchClosed">批量关闭</el-button>
          <el-button  @click="inviteAction">邀请逻辑</el-button>
          <el-button  @click="EditLog">操作日志</el-button>
        </div>
      </div>

      <el-table
        :data="tableData"
        style="width: 100%"
        border
        ref="tableRef"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="40"
          align="center"
        ></el-table-column>
        <el-table-column label="助手名称" prop="RobotName"></el-table-column>
        <el-table-column prop="RobotAlias" label="助手ID"></el-table-column>
        <el-table-column prop="GroupCount" label="关联群数"></el-table-column>
        <el-table-column prop="Status" label="入群状态">
          <template #default="scope">
            <div class="action-wrap">
              <el-switch
                v-model="scope.row.Status"
                active-color="#13ce66"
                @change="changeSwitch(scope.row)"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="240">
          <template #default="scope">
            <div class="action-wrap">
              <el-button type="primary" @click="GoGroupDetail(scope.row)">群明细</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="foot-wrap">
        <TableFoot
          :pagination="pageObj"
          @changePage="changePage"
          @changeSize="changeSize"
        />
      </div>
    </div>
    <InviteCop ref="inviteRef"/>
  </div>
</template>
<script>
import { reactive, ref, onMounted,nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import TableFoot from "@/components/TableFoot";
import InviteCop from "@/components/GroupManager/InviteCop";
import { ElMessageBox, ElMessage } from "element-plus";
import {PromotionTaskGetList,PromotionTaskSetStatus} from "@/helper/promotion";
import Tool from "@/assets/js/tools";
import { useStore } from "vuex";
export default {
  name: "groupadd",
  components: {
    TableFoot,InviteCop
  },
  beforeRouteEnter(to, from, next){
        next(vm=>{
            if(from.path == '/grouphelperdetail'){
                to.meta.getStore = true
            }
        })
    },
  beforeRouteLeave(to, from, next){
        this.$store.commit("GroupAdd/setData",{
            pageObj:this.pageObj,
            searchOption:this.searchOption,
        })
        next()
  },
  setup() {
    const router = useRouter();
    const tableRef = ref(null);
    const tableData = ref([]);
    const store = useStore()
    const route = useRoute()
    onMounted(() => {
      resetStore()
      searchAction();
    });
    function resetStore(){
        if(route.meta.getStore&& store.state.GroupAdd.pageObj&&store.state.GroupAdd.searchOption){
            pageObj.TotalRow= store.state.GroupAdd.pageObj.TotalRow,
            pageObj.PageIndex= store.state.GroupAdd.pageObj.PageIndex,
            pageObj.PageSize=store.state.GroupAdd.pageObj.PageSize,
            searchOption.key= store.state.GroupAdd.searchOption.key
            searchOption.status= store.state.GroupAdd.searchOption.status  
        }
    }

    /******************点击事件*********************/
    const inviteRef=ref(null)
    //邀请逻辑
    function inviteAction(){
      nextTick(()=>{
        inviteRef.value.initCop()
      })
      
    }

    //操作日志
    function EditLog(){
      router.push({name: "GroupEditLog"});
    }
    //批量关闭
    function BatchClosed() {
      if (selectData.value.length > 0) {
        // let status = "";
        // for (let item of selectData.value) {
        //   status = item.Status;
        //   console.log(status);
        // }
        let param = {
          RobotSerialNo: selectData.value.map((item) => item.RobotSerialNo),
          Switch: 0,
        };
        console.log(param)
        ElMessageBox.confirm("是否需要批量关闭？", "确认提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
            PromotionTaskSetStatus(param).then((res) => {
              searchAction()
            });
        }).catch(() => {});
      } else {
        ElMessage({
          type: "error",
          message: "请至少选择一个助手",
        });
      }
    }
    //去群明细
    function GoGroupDetail(item) {
      router.push({
        name: "GroupHelperDetail",
        query: { SID: item.RobotSerialNo },
      });
    }
    //table 选择变更
    const selectData = ref([]);
    function handleSelectionChange(val) {
      selectData.value = val;
    }

    /**********************搜索***************************/
    const statusList = [
      { label: "全部", value: 0 },
      { label: "正常", value: 10 },
      { label: "离线", value: 11 },
    ];
    const searchOption = reactive({
      key: "",
      status: 0,
    });

    //执行搜索
    function searchAction() {
      let param = {
        Name: searchOption.key || "",
        Type: searchOption.status,
        PageNum: pageObj.PageIndex,
        PageSize: pageObj.PageSize,
      };
      PromotionTaskGetList(param).then((res) => {
        pageObj.TotalRow = res.TotalCount;
        tableData.value = res.List;
        tableData.value.forEach((item) => {
          item.Status = item.Status == 1 ? true : false;
        });
      });
    }
    //改变入群状态
    function changeSwitch(item) {
      let param = {
        RobotSerialNo: [item.RobotSerialNo],
        Switch: item.Status ? 1 : 0,
      };
      PromotionTaskSetStatus(param)
        .then((res) => {})
        .catch(() => {
          item.Status = !item.Status;
        });
    }
    //重置条件
    function resetAction() {
      searchOption.key = "";
      searchOption.status = 0;
      pageObj.PageIndex = 1
      searchAction();
    }

    /****************TableFoot*******************/
    const pageObj = reactive({
      TotalRow: 0,
      PageIndex: 1,
      PageSize: 10,
    });
    //页码改变
    function changePage(index) {
      pageObj.PageIndex = index;
      searchAction();
    }
    function changeSize(size) {
      pageObj.PageSize = size;
      pageObj.PageIndex = 1;
      searchAction();
    }

    return {
      tableData,
      BatchClosed,
      GoGroupDetail,
      changeSwitch,
      searchOption,
      statusList,
      resetAction,
      searchAction,
      changePage,
      changeSize,
      pageObj,
      tableRef,
      handleSelectionChange,
      selectData,
      EditLog,
      inviteAction,
      inviteRef
    };
  },
};
</script>
<style lang="scss" scoped>
.action-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 15px;
    height: 13px;
    margin-left: 2px;
  }
}

.tag-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  span {
    margin-right: 5px;
  }

  .tag-cell {
    padding: 5px;
    margin: 2px 0;
    height: 20px;
    font-size: 12px;
    // background-color: #97D1F4;
    // color: #787C7E;
    // border: 1px solid #ccc;
    // border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.tag-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  .tag-search {
    width: 200px;
    height: 26px;

    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    border: 1px solid #dddddd;
    border-radius: 5px;

    p {
      width: 180px;
      height: 100%;
      overflow: hidden;
      word-break: break-all;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      padding: 0 5px;
      box-sizing: border-box;
    }
    div {
      width: 20px;
      height: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
}

.foot-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    margin-left: 3px;

    .selectAll {
      margin: 0 10px;
    }
  }
}
.list-title {
  font-size: 14px;
}
.list-title div span {
  color: $color-common;
}
.selected {
  background-color: $color-common;
  color: #fff;
}

</style>