import $axios from '../assets/js/request.js'
import crypto from '../assets/js/crypto.js'
let baseURL = process.env.VUE_APP_URL

//助手入群列表页
export function PromotionTaskGetList(data) {
    return $axios.post(`${baseURL}/PromotionTask/GetList`,data);

}
//群或机器人禁用助手入群
export function PromotionTaskSetStatus(data) {
    return $axios.post(`${baseURL}/PromotionTask/SetStatus`,data);
}
//助手入群详情页
export function PromotionTaskGetDetail(data) {
    return $axios.post(`${baseURL}/PromotionTask/GetDetail`,data);
}
//设置机器人引流入群规则
export function PromotionTaskSetRule(data) {
    return $axios.post(`${baseURL}/PromotionTask/SetRule`,data);
}
//增加群到引流任务
export function PromotionTaskAddGroup(data) {
    return $axios.post(`${baseURL}/PromotionTask/AddGroup`,data);
}
//调整引流任务顺序
export function PromotionTaskSort(data) {
    return $axios.post(`${baseURL}/PromotionTask/Sort`,data);
}
//指定群的标签规则
export function PromotionTaskSetTagRule(data) {
    return $axios.post(`${baseURL}/PromotionTask/SetTagRule`,data);
}
//获取标签规则
export function PromotionTaskGetTagRule(data) {
    return $axios.post(`${baseURL}/PromotionTask/GetTagRule`,data);
}
//删除标签规则
export function PromotionTaskDeleteTagRule(data) {
    return $axios.post(`${baseURL}/PromotionTask/DeleteTagRule`,data);
}
//获取所有群列表(选择群使用)
export function PromotionTaskGetAllGroup(data) {
    return $axios.post(`${baseURL}/PromotionTask/GetAllGroup`,data);
}
//助手入群操作日志
export function PromotionTaskGetLog(data) {
    return $axios.post(`${baseURL}/PromotionTask/GetLog`,data);
}

